import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SEOService } from './services/seo.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'sbgroup-landing';
  public awaitLibs:boolean = false;
  constructor( 
    private actRoute: ActivatedRoute,
    private seo: SEOService
  ) { 
  }
  
  ngOnInit() {
    // console.log( this.actRoute.snapshot.routeConfig.component.name );
    // this.seo.metaTags('IndexComponent' );
  }

  ngAfterViewInit() {
  }

}
