import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient) { }

  segments() {
    return this.http.get(`${environment.sysConfig.awsWsBaseUrl}/segments`, {
      headers: new HttpHeaders({ "x-api-key": environment.sysConfig.awsWsApiKey })
    });
  }

  clients(data) {
    return this.http.post(`${environment.sysConfig.awsWsBaseUrl}/clients`,data, {
      headers: new HttpHeaders({ "x-api-key": environment.sysConfig.awsWsApiKey })
    });
  }

}
