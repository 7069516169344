import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconsComponent } from './svg-icons/svg-icons.component';



@NgModule({
  declarations: [
    SvgIconsComponent
  ],exports: [
    CommonModule,
    SvgIconsComponent
  ]
})
export class SharedModule { }
