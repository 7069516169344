import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CardService } from '../services/card.service';
import { SEOService } from '../services/seo.service';
import { RecaptchaErrorParameters } from 'ng-recaptcha';
import { environment } from '../../environments/environment';
import { ContactService } from '../services/contact.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css'],
})
export class ContactoComponent implements OnInit {
  contactForm: any = FormGroup;
  serviceOpts: any;
  submitted = false;
  isButtonEnabled = true;
  sendButton = 1;
  siteKey = environment.sysConfig.siteKey;

  constructor(
    private cardService: CardService,
    private seo: SEOService,
    private contactService: ContactService,
    private actRoute: ActivatedRoute,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.seo.metaTags(
      String(this.actRoute.snapshot.routeConfig.component.name)
    );

    this.contactService
      .segments()
      .subscribe((response) => (this.serviceOpts = response));

    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      phoneNumber: [
        '',
        [
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.minLength(10),
          Validators.maxLength(10),
        ],
      ],
      country: ['MX', Validators.required],
      segment: ['', Validators.required],
      message: [''],
      captcha: [''],
    });
  }

  sendContactInfo() {
    this.isButtonEnabled = false;
    this.submitted = true;
    if (
      this.contactForm.valid &&
      ((this.serviceSegments.length && !!this.subsegment) ||
        !this.serviceSegments.length)
    ) {
      const segment = !!this.subsegment
        ? this.subsegment
        : this.contactForm.value.segment;

      const successBox = document.getElementById('alert-success');
      const errorBox = document.getElementById('alert-error');

      this.contactService
        .clients({ ...this.contactForm.value, segment })
        .subscribe((res) => {
          this.cardService
            .emailInformation({
              ...this.contactForm.value,
              segment,
              lang: 'esp',
            })
            .subscribe((response) => {
              this.isButtonEnabled = true;
              if (response.status === 1) {
                this.contactForm.reset();
                successBox.style.display = 'block';
              } else {
                this.contactForm.controls.captcha.reset();
                errorBox.style.display = 'block';
              }

              this.submitted = false;

              setTimeout(() => {
                successBox.style.display = 'none';
                errorBox.style.display = 'none';
              }, 5000);
            });
        });
    }
    this.isButtonEnabled = true;
  }

  public resolved(captchaResponse: string): void {
    if (captchaResponse === null) {
      this.sendButton = 0;
      return;
    }
    // console.log('captchaResponse', captchaResponse);
    this.cardService
      .recaptchaVerification(captchaResponse)
      .subscribe((response) => {
        // console.log('captcha verification', response)
        if (response.success === true) {
          this.sendButton = 1;
        } else {
          this.sendButton = 0;
        }
      });
  }

  public onError(errorDetails: RecaptchaErrorParameters): void {
    this.sendButton = 0;
  }

  get serviceSegments() {
    if (this.contactForm.value.segment) {
      const index = this.serviceOpts.findIndex(
        (i) => i.name == this.contactForm.value.segment
      );

      return this.serviceOpts[index].subsegments;
    }

    return [];
  }

  get contactFormControl() {
    return this.contactForm.controls;
  }

  get subsegment() {
    const subsegment = document.getElementById(
      'subsegmento'
    ) as HTMLInputElement;
    return subsegment ? subsegment.value : null;
  }
}
