// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  sysConfig:{
    // apiUrl: 'https://ws.sbgroup.com.mx/',
    apiUrl: 'https://ws.sbgroup.com.mx/',
    // siteKey: '6LfrQX0aAAAAACt_A36YeSwy9VG1fmdt5V70SQ5C',
    // secretKey: '6LfrQX0aAAAAAKzKu8wYIxD6Pv1j_AMfKU_UDyqM',
    siteKey: '6LfXQtIgAAAAAA-krbUUna-tyNbQ4xd5D3OOHcT7',
    secretKey: '6LfXQtIgAAAAAPWVtFFkw3qAJeoZcfqJ5E1N1ULO',
    satWsBaseUrl: 'https://api.sandbox.sat.ws',
    satWsApiKey: 'c1eab53e9c09474763203a6162d6982a',
    awsWsBaseUrl: 'https://d1n3hxfto2.execute-api.us-west-2.amazonaws.com/dev',
    awsWsApiKey: 'OYc2MapKSe7gwt4cpm1C27KfYYY5umNN4B5O75EL',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
