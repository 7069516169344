		<!-- whatsapp -->
		<a href="https://api.whatsapp.com/send?phone=5214448379970&text=Hola!%20estoy%20interesado,%20en%20realizar%20mi%20cotización" class="whatsapp" target="_blank"> <i class="fa fa-whatsapp whatsapp-icon"></i></a>
		<div class="page-wrapper">
			<!-- menu dropdown start-->
			<div class="menu-dropdown">
				<div class="menu-dropdown__inner" data-value="start">
					<div class="screen screen--start">
						<div class="menu-dropdown__close">
							<svg class="icon">
								<use xlink:href="#close"></use>
							</svg>
						</div>
						<div class="d-block d-lg-none bottom-20">
							<div class="screen__item screen--trigger item--active" data-category="screen-one"><a href="#">Inicio</a></div>
							<div class="screen__item screen--trigger" data-category="screen-two"><span><a href="sb-logistics.html">SBLogistics</a></span></div>
							<div class="screen__item screen--trigger" data-category="screen-three"><span><a href="sb-free-trade-zone.html">SBFree Trade Zone</a></span></div>
							<div class="screen__item screen--trigger" data-category="screen-five"><span><a href="sb-financial-services.html">SBFinancial Services</a></span></div>
							<div class="screen__item screen--trigger" data-category="screen-five"><span><a href="blog.html">Blog</a></span></div>
							<div class="screen__item screen--trigger" data-category="screen-four"><span><a href="contacto.html">Contacto</a></span></div>
						</div>

						<div class="screen__item screen--trigger d-flex" data-category="screen-six"><span>Idioma</span><span>
							<svg class="icon">
								<use xlink:href="#chevron-right"></use>
							</svg></span>
						</div>

						<div class="menu-dropdown__block top-50"><span class="block__title">Conoce más</span><a class="screen__link" href="sb-logistics.html#servicio-terrestre"><strong>LTL Fast Pass</strong></a></div>
						<div class="menu-dropdown__block top-20"><a class="screen__link" href="sb-logistics.html#red-de-distribucion"><strong>Red de distribución</strong></a></div>
						<div class="menu-dropdown__block top-20"><a class="screen__link" href="sb-free-trade-zone.html#ubicaciones"><strong>Ubicaciones</strong></a></div>
						<div class="menu-dropdown__block top-50"><span class="block__title">Email</span><a class="screen__link" href="mailto:sales@sbgroup.com.mx">sales@sbgroup.com.mx</a></div>
						<div class="menu-dropdown__block top-20"><span class="block__title">Email</span><a class="screen__link" href="mailto:salesusa@sbgroup.com.mx">salesusa@sbgroup.com.mx</a></div>
						<div class="menu-dropdown__block top-20"><span class="block__title">Phone numbers</span><a class="screen__link" href="tel:523336124440">+52 (33) 3612 4440</a></div>
						<div class="menu-dropdown__block top-20"><span class="block__title">Phone numbers</span><a class="screen__link" href="tel:18004583460">+ 1 (800) 458 3460</a></div>
						<div class="menu-dropdown__block">
							<ul class="socials list--reset">
								<li class="socials__item"><a class="socials__link" href="https://www.linkedin.com/company/sbgroup-mexico" target="_blank">
									<svg class="icon">
										<use xlink:href="#linkedin"></use>
									</svg></a></li>
								<li class="socials__item"><a class="socials__link" href="https://www.facebook.com/SBGroupMexico" target="_blank">
									<svg class="icon">
										<use xlink:href="#facebook"></use>
									</svg></a></li>
								<li class="socials__item"><a class="socials__link" href="https://twitter.com/sbgroup_mx" target="_blank">
									<svg class="icon">
										<use xlink:href="#twitter"></use>
									</svg></a></li>
								<li class="socials__item"><a class="socials__link" href="https://www.youtube.com/channel/UCQFOGP1Bfdc037z7C8owPjA" target="_blank">
									<svg class="icon">
										<use xlink:href="#youtube"></use>
									</svg></a></li>
								<li class="socials__item"><a class="socials__link" href="https://www.instagram.com/sbgroup.mx/" target="_blank">
									<svg class="icon">
										<use xlink:href="#inst"></use>
									</svg></a></li>
							</ul>
						</div>
						<div class="menu-dropdown__block top-50"><a class="button button--filled white-btn-color" href="contacto.html">Solicitar cotización</a>
						</div>
						<div class="menu-dropdown__block top-20"><a class="button button--filled white-btn-color-2" href="pasos.html">Consultar con experto</a>
						</div>
					</div>
				</div>
				<div class="menu-dropdown__inner" data-value="screen-six">
					<div class="screen screen--sub">
						<div class="screen__heading">
							<h6 class="screen__back">
								<svg class="icon">
									<use xlink:href="#chevron-left"></use>
								</svg> <span>Idioma</span>
							</h6>
						</div>
						<div class="screen__item"><a class="screen__link" href="eng/index.html">English</a></div>
						<div class="screen__item"><a class="screen__link" href="#">Español</a></div>
						<!-- <div class="screen__item"><a class="screen__link" href="deu/index.html">Deutch</a></div> -->
						<!-- <div class="screen__item"><a class="screen__link" href="deu/index.html">Deutch</a></div> -->
					</div>
				</div>
			</div>
			<!-- menu dropdown end-->
			<!-- header start-->
			<header class="page-header_2">
				<div class="page-header__top d-none d-xl-block">
					<div class="container-fluid">
						<div class="row align-items-center">
							<div class="col-xl-8"><span>
								<svg class="icon">
									<use xlink:href="#phone"></use>
								</svg><a href="tel:523336124440">+52 (33) 3612 4440</a></span><span>
									<svg class="icon">
										<use xlink:href="#phone"></use>
									</svg><a href="tel:18004583460">+1 (800) 458 3460</a></span><span>
								<svg class="icon">
									<use xlink:href="#mail"></use>
								</svg><a href="mailto:sales@sbgroup.com.mx">sales@sbgroup.com.mx</a></span><span>
									<svg class="icon">
										<use xlink:href="#mail"></use>
									</svg><a href="mailto:salesusa@sbgroup.com.mx">salesusa@sbgroup.com.mx</a></span></div>
							<div class="col-xl-2">
								<div class="lang-block">
									<div class="lang-icon"><a href="#"><img loading="lazy" src="../../assets/img/ESP.svg" alt="esp"/></a></div>
									<div class="lang-icon"><a href="eng/index.html"><img loading="lazy" src="../../assets/img/gbr.png" alt="eng"/></a></div>
									<!-- <div class="lang-icon"><a href="deu/index.html"><img loading="lazy" src="../../assets/img/deu.png" alt="deu"/></a></div> -->
									<!-- <div class="lang-icon"><a href="deu/index.html"><img loading="lazy" src="../../assets/img/deu.png" alt="deu"/></a></div> -->
									<!-- lang select start-->
								</div>
							</div>
							<div class="col-xl-2 text-right">
								<ul class="socials list--reset">
									<li class="socials__item"><a class="socials__link" href="https://www.linkedin.com/company/sbgroup-mexico" target="_blank">
										<svg class="icon">
											<use xlink:href="#linkedin"></use>
										</svg></a></li>
									<li class="socials__item"><a class="socials__link" href="https://www.facebook.com/SBGroupMexico" target="_blank">
										<svg class="icon">
											<use xlink:href="#facebook"></use>
										</svg></a></li>
									<li class="socials__item"><a class="socials__link" href="https://twitter.com/sbgroup_mx" target="_blank">
										<svg class="icon">
											<use xlink:href="#twitter"></use>
										</svg></a></li>
									<li class="socials__item"><a class="socials__link" href="https://www.youtube.com/channel/UCQFOGP1Bfdc037z7C8owPjA" target="_blank">
										<svg class="icon">
											<use xlink:href="#youtube"></use>
										</svg></a></li>
									<li class="socials__item"><a class="socials__link" href="https://www.instagram.com/sbgroup.mx/" target="_blank">
										<svg class="icon">
											<use xlink:href="#inst"></use>
										</svg></a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div class="page-header__lower">
					<div class="container-fluid">
						<div class="row align-items-center">
							<div class="col-8 col-md-3 col-lg-2 d-flex align-items-center">
								<div class="hamburger d-none d-md-inline-block hamburger--white">
									<div class="hamburger-inner"></div>
								</div>
								<div class="page-header__logo logo--white"><a href="#"><img loading="lazy" src="assets/img/logo_sbgroup_webp.webp" alt="logo"/></a></div>
								<div class="page-header__logo logo--dark"><a href="#"><img loading="lazy" src="../../assets/img/logo sb-b.svg" alt="logo"/></a></div>
							</div>
							<div class="col-lg-6 d-none d-lg-block">
								<!-- main menu start-->
								<ul class="main-menu main-menu--white">
									<li class="main-menu__item main-menu__item--has-child main-menu__item--active"><a class="main-menu__link" href="#"><span>Inicio</span></a></li>
									<li class="main-menu__item main-menu__item--has-child green-menu"><a class="main-menu__link" href="sb-logistics.html"><span>SBLogistics</span></a>
										<!-- sub menu start-->
										<ul class="main-menu__sub-list">
											<li><a href="sb-logistics.html#transportacion-internacional"><span>Transportación Internacional</span></a></li>
											<li><a href="sb-logistics.html#extiende-tu-infraestructura"><span>Almacenaje</span></a></li>
											<li><a href="sb-logistics.html#crossborder"><span>Crossborder</span></a></li>
											<li><p class="login-p">Enlaces externos</p></li>
											<li><a href="http://podsblogistics.com/" target="_blank"><span>PODtracking</span></a></li>
											<li><a href="http://sbljlc.webtracker.wisegrid.net/Login/Login.aspx?ReturnUrl=%2fDefault.aspx" target="_blank"><span>Inventario en Línea</span></a></li>
											<li><a href="http://proveedores.sbgroup.com.mx/" target="_blank"><span>Proveedores</span></a></li>
										</ul>
										<!-- sub menu end-->
									</li>
									<li class="main-menu__item main-menu__item--has-child orange-menu"><a class="main-menu__link" href="sb-free-trade-zone.html"><span>SBFree Trade Zone</span></a>
										<!-- sub menu start-->
										<ul class="main-menu__sub-list">
											<li><a href="sb-free-trade-zone.html#recinto-fizcalizado"><span>Recinto Fiscalizado</span></a></li>
											<li><a href="sb-free-trade-zone.html#recinto-fiscalizado-estrategico"><span>Recinto Fiscalizado Estratégico</span></a></li>
											<li><a href="sb-free-trade-zone.html#immex"><span>IMMEX</span></a></li>
											<li><a href="sb-free-trade-zone.html#despacho-aduanal"><span>Despacho Aduanal</span></a></li>
										</ul>
										<!-- sub menu end-->
									</li>
									<li class="main-menu__item main-menu__item--has-child blue-menu"><a class="main-menu__link" href="sb-financial-services.html"><span>SBFinancial Services</span></a>
										<!-- sub menu start-->
										<ul class="main-menu__sub-list">
											<li><a href="sb-financial-services.html#arrendamiento"><span>Arrendamiento</span></a></li>
											<li><a href="sb-financial-services.html#inmobiliaria"><span>Inmobiliaria</span></a></li>
											<li><a href="sb-financial-services.html#asesoria-en-gestion-de-riesgos"><span>Asesoría en Gestión de Riesgos</span></a></li>
										</ul>
										<!-- sub menu end-->
									</li>
									<li class="main-menu__item main-menu__item--has-child"><a class="main-menu__link" href="blog.html"><span>Blog</span></a></li>
									<li class="main-menu__item main-menu__item--has-child"><a class="main-menu__link" href="contacto.html"><span>Contacto</span></a></li>
								</ul>
								<!-- main menu end-->
							</div>
							<div class="col-4 col-md-6 col-lg-4 d-flex justify-content-end align-items-center">
									<a class="button button--filled white-btn-color" href="pasos.html">Consultar con experto</a>
									<a class="button button--filled gray-btn-color" href="contacto.html">Solicitar cotización</a>
								<!-- menu-trigger start-->
								<div class="hamburger d-inline-block d-md-none hamburger--white">
									<div class="hamburger-inner"></div>
								</div>
								<!-- menu-trigger end-->
							</div>
						</div>
					</div>
				</div>
			</header>
			<!-- header end-->
			<main class="main">
				<!-- promo start-->
				<div class="front-promo">
					<div class="front-promo__layout"></div>

					<picture>
						<source rel="preload" srcset="assets/img/banner-home.webp" media="(min-width: 750px)"/>
						<img loading="lazy" class="img--bg" rel="preload" src="assets/img/banner-home.webp" alt="banner"/>
					</picture>
					<div class="align-container">
						<div class="align-container__item">
							<div class="container">
								<div class="row align-items-center">
									<div class="col-lg-12 col-xl-12 text-center">
										<h2 class="front-promo__title">Brindamos soluciones de transporte internacional, logística, distribución y servicios financieros a la medida de tu empresa </h2>
										<p class="front-promo__subtitle">Agenda tu cita sin costo. <br> Nuestros expertos te están esperando​</p>
										<a class="button button--filled white-btn-color-2" href="pasos.html"><span>Hablar con experto</span>
											<svg class="icon">
												<use xlink:href="#arrow"></use>
											</svg></a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="promo-pannel">
						<div class="promo-pannel__inner">
							<div class="row">
								<div class="col-4 bg-1">
									<a href="sb-logistics.html"><img loading="lazy" src="../../assets/img/logo_sblogistics.png" alt="SBLogistics"></a>
								</div>
								<div class="col-4 bg-2">
									<a href="sb-free-trade-zone.html"><img loading="lazy" src="../../assets/img/logo_freezone.png" alt="SBFree Trade Zone"></a>
								</div>
								<div class="col-4 bg-3">
									<a href="sb-financial-services.html"><img loading="lazy" src="../../assets/img/logo-financial.png" alt="SBFinancial Services"></a>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- promo end-->
				<!-- section start-->
				<section class="section pt-0 section-bg-three">
					<div class="container-fluid">
						<div class="row flex-column-reverse flex-lg-row">
							<div class="col-lg-7 top-50 top-lg-0 bg-green three-sbg">
								<img loading="lazy" src="../../assets/img/logo_sblogistics.png" class="bottom-40" alt="SBLogistics">
								<div class="heading bottom-20 space-top">
									<h3 class="heading__title">Global growth starts with local expertise</h3>
								</div>
								<p class="top-20">Tu cadena de suministro es única por eso integramos las mejores soluciones en nuestros servicios.</p>
								<div class="col-12 nopadding">
									<div class="row">
										<div class="col-md-6">
											<a class="button button--white" href="sb-logistics.html"><span>Conoce más</span>
												<svg class="icon">
													<use xlink:href="#arrow"></use>
												</svg>
											</a>
										</div>
										<div class="col-md-6 col-sm-6 col-xs-6 text-center img-margin">
											<div class="col-12">
												<div class="row">
													<div class="col-md-4">
														<img loading="lazy" src="../../assets/img/avion1.svg" alt="avion">
													</div>
													<div class="col-md-4">
														<img loading="lazy" src="../../assets/img/barco1.png" alt="barco">
													</div>
													<div class="col-md-4">
														<img loading="lazy" src="../../assets/img/camion1.svg" alt="camion">
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-lg-5 nopadding home1"></div>
						</div>
					</div>
				</section>
				<!-- section end-->
				<!-- section start-->
				<section class="section pt-0 section-bg-three">
					<div class="container-fluid">
						<div class="row flex-lg-row">
							<div class="col-lg-5 nopadding home2"></div>
							<div class="col-lg-7 top-50 top-lg-0 bg-orange three-sbg">
								<img loading="lazy" src="../../assets/img/logo_freezone.png" class="bottom-40" alt="SBFree Trade Zone">
								<div class="heading bottom-20 space-top">
									<h3 class="heading__title">Facilitamos y promovemos el despacho de mercancías en México</h3>
								</div>
								<p class="top-20">Con infraestructura robusta y cumplimiento en comercio exterior.</p>
								<div class="col-12 nopadding">
									<div class="row">
										<div class="col-md-4">
											<a class="button button--white" href="sb-free-trade-zone.html"><span>Conoce más</span>
												<svg class="icon">
													<use xlink:href="#arrow"></use>
												</svg>
											</a>
										</div>
										<div class="col-md-8 text-center img-margin">
											<div class="col-12">
												<div class="row">
													<div class="col-md-3">
														<img loading="lazy" src="../../assets/img/almacen2.png" alt="almacen">
													</div>
													<div class="col-md-3">
														<img loading="lazy" src="../../assets/img/alm2.png" alt="almacen">
													</div>
													<div class="col-md-3">
														<img loading="lazy" src="../../assets/img/banda2.png" alt="IMMEX">
													</div>
													<div class="col-md-3">
														<img loading="lazy" src="../../assets/img/pin2.png" alt="Despacho aduanal">
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<!-- section end-->
				<!-- section start-->
				<section class="section pt-0 section-bg-three">
					<div class="container-fluid">
						<div class="row flex-column-reverse flex-lg-row">
							<div class="col-lg-7 top-50 top-lg-0 bg-blue three-sbg">
								<img loading="lazy" src="../../assets/img/logo-financial.png" class="bottom-40" alt="SBFinancial Services">
								<div class="heading bottom-20 space-top">
									<h3 class="heading__title">Brindamos soluciones financieras y protección para nuestros clientes haciendo uso de la tecnología</h3>
								</div>
								<p class="top-20">para impulsar ágilmente su negocio.</p>
								<div class="col-12 nopadding">
									<div class="row">
										<div class="col-md-6">
											<a class="button button--white" href="sb-financial-services.html"><span>Conoce más</span>
												<svg class="icon">
													<use xlink:href="#arrow"></use>
												</svg>
											</a>
										</div>
										<div class="col-md-6 text-center img-margin">
											<div class="col-12">
												<div class="row">
													<div class="col-md-4">
														<img loading="lazy" src="../../assets/img/manos3.png" alt="Arrendamiento">
													</div>
													<div class="col-md-4">
														<img loading="lazy" src="../../assets/img/edi3.png" alt="Inmobiliaria">
													</div>
													<div class="col-md-4">
														<img loading="lazy" src="../../assets/img/cuadro3.png" alt="gestión de riesgos">
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-lg-5 nopadding home3"></div>
						</div>
					</div>
				</section>
				<!-- section end-->
				<!-- section start-->
				<section class="section">
					<div class="container">
						<div class="row flex-column-reverse flex-xl-row">
							<div class="col-md-8 col-lg-7 col-xl-6 top-50 top-xl-0">
								<div class="counters-holder text-center text-sm-left"><img loading="lazy" class="img--bg" src="../../assets/img/map-bg.png" alt="background"/>
									<div class="row offset-50">
										<div class="col-12">
											<div class="counter counter--white">
												<div class="counter__top"><span class="counter__subject color--green">+</span><span class="js-counter counter__count color--green">12</span><span class="counter__subject color--green">años</span></div>
												<div class="counter__lower"><span class="counter__details color--lgray">de experiencia</span></div>
											</div>
										</div>
										<div class="col-12">
											<div class="counter counter--white">
												<div class="counter__top"><span class="counter__subject color--orange">+</span><span class="js-counter counter__count color--orange">65,000</span><span class="counter__subject color--orange">m<sup>2</sup></span></div>
												<div class="counter__lower"><span class="counter__details color--lgray">de área disponible</span></div>
											</div>
										</div>
										<div class="col-12">
											<div class="counter counter--white">
												<div class="counter__top"><span class="counter__subject color--gray">+</span><span class="js-counter counter__count color--gray">280</span></div>
												<div class="counter__lower"><span class="counter__details color--lgray">profesionales al servicio</span></div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-xl-5 offset-xl-1">
								<div class="heading bottom-20"><span class="heading__pre-title"></span>
									<h3 class="heading__title">Quiénes somos</h3><span class="heading__layout"></span>
								</div>
								<p>Somos un integrador de soluciones logísticas y financieras cuya plataforma a nivel nacional y servicios permiten a nuestros socios comerciales y clientes contar con infraestructura en las zonas de mayor crecimiento económico e industrial del país integrados por los siguientes segmentos de negocio:</p>
								<p>• SBLogistics<br>
								   • SBFree Trade Zone<br>
								   • SBFinancial Services</p>
								<p class="bottom-40">Contamos con las principales certificaciones y estándares de seguridad, calidad y cumplimiento en normatividad aduanera y de transporte nacional e internacional.</p>
							</div>
						</div>
					</div>
				</section>
				<!-- section end-->
				<!-- section start-->
				<section class="section video-section pt-0 pb-0">
					<div class="container-fluid">
						<div class="row">
							<div class="col-12 nopadding"><a class="js-video video-block" href="../../assets/img/sb-group-EstamosTrazandoElCamino.mp4"><img loading="lazy" class="img--bg" src="../../assets/img/video-bg.webp" alt="video"/>
								<div class="video-block__inner"><span class="video-icon">
									<svg class="icon">
										<use xlink:href="#play"></use>
									</svg></span>
								</div></a>
							</div>
						</div>
					</div>
				</section>
				<!-- section end-->
				<!-- section start-->
				<section class="section pb-0 services_2">
					<div class="container bottom-70">
						<div class="row">
							<div class="col-md-12">
								<div class="heading"><span class="heading__pre-title"></span>
									<h3 class="heading__title">Misión y visión</h3><span class="heading__layout layout--lgray"></span>
									<p class="top-30">Facilitar a nuestros clientes su operación en el país por medio de una red propia nacional e infraestructura en puertos interiores otorgando soluciones flexibles en logística.</p>

									<p>Nuestra visión es ser el grupo mexicano con la plataforma logística más completa, reconocida a nivel internacional y con los más altos estándares de certificación.</p>

									<p>Somos respaldados por nuestros principios ya que demostramos confianza con cada compromiso cumplido, nuestra fuerza con nuestro equipo de trabajo, nuestra versatilidad por medio de un pensamiento abierto y nuestro objetivo de siempre aspirar el éxito. Queremos lograr excelentes resultados optimizando tus recursos, siempre respetando a nuestros clientes y enfrentando los retos con una actitud positiva.</p>
								</div>
							</div>
						</div>
					</div>
				</section>
				<!-- section end-->
				<!-- section start-->
				<section class="section w-s green-dots" id="gallery-family">
					<div class="container bottom-70 top-20">
						<div class="row">
							<div class="col-lg-12 text-center">
								<div class="heading heading--white">
									<h3 class="heading__title">Nuestra <span class="color--green">Familia</span></h3>
									<p class="top-10">Somos una familia que crece y se capacita día con día para crear el mejor ambiente para nuestros colaboradores y clientes</p>
								</div>
							</div>
						</div>
					</div>
					<div class="contenedor">
						<div class="carousel">
							<div class="carousel__contenedor">
								<button aria-label="Anterior" class="carousel__anterior">
									<i class="fas fa-chevron-left"></i>
								</button>

								<div class="carousel__lista">
									<div class="carousel__elemento">
										<img loading="lazy" src="../../assets/img/gallery-1.webp" alt="image">
									</div>
									<div class="carousel__elemento">
										<img loading="lazy" src="../../assets/img/gallery-2.webp" alt="image">
									</div>
									<div class="carousel__elemento">
										<img loading="lazy" src="../../assets/img/gallery-3.webp" alt="Empire State Building">
									</div>
									<div class="carousel__elemento">
										<img loading="lazy" src="../../assets/img/gallery-4.webp" alt="Harmony Tower">
									</div>
									<div class="carousel__elemento">
										<img loading="lazy" src="../../assets/img/gallery-5.webp" alt="Empire State Building">
									</div>
									<div class="carousel__elemento">
										<img loading="lazy" src="../../assets/img/gallery-6.webp" alt="Harmony Tower">
									</div>
									<div class="carousel__elemento">
										<img loading="lazy" src="../../assets/img/gallery-7.webp" alt="Empire State Building">
									</div>
									<div class="carousel__elemento">
										<img loading="lazy" src="../../assets/img/gallery-8.webp" alt="Harmony Tower">
									</div>
									<div class="carousel__elemento">
										<img loading="lazy" src="../../assets/img/gallery-9.webp" alt="image">
									</div>
									<div class="carousel__elemento">
										<img loading="lazy" src="../../assets/img/gallery-10.webp" alt="image">
									</div>
									<div class="carousel__elemento">
										<img loading="lazy" src="../../assets/img/gallery-11.webp" alt="Empire State Building">
									</div>
									<div class="carousel__elemento">
										<img loading="lazy" src="../../assets/img/gallery-12.webp" alt="Harmony Tower">
									</div>
									<div class="carousel__elemento">
										<img loading="lazy" src="../../assets/img/gallery-13.webp" alt="Empire State Building">
									</div>
									<div class="carousel__elemento">
										<img loading="lazy" src="../../assets/img/gallery-14.webp" alt="Harmony Tower">
									</div>
									<div class="carousel__elemento">
										<img loading="lazy" src="../../assets/img/gallery-15.webp" alt="Empire State Building">
									</div>
									<div class="carousel__elemento">
										<img loading="lazy" src="../../assets/img/gallery-16.webp" alt="Harmony Tower">
									</div>
									<div class="carousel__elemento">
										<img loading="lazy" src="../../assets/img/gallery-17.webp" alt="image">
									</div>
									<div class="carousel__elemento">
										<img loading="lazy" src="../../assets/img/gallery-18.webp" alt="image">
									</div>
									<div class="carousel__elemento">
										<img loading="lazy" src="../../assets/img/gallery-19.webp" alt="Empire State Building">
									</div>
									<div class="carousel__elemento">
										<img loading="lazy" src="../../assets/img/gallery-20.webp" alt="Harmony Tower">
									</div>
									<div class="carousel__elemento">
										<img loading="lazy" src="../../assets/img/gallery-21.webp" alt="Empire State Building">
									</div>
									<div class="carousel__elemento">
										<img loading="lazy" src="../../assets/img/gallery-22.webp" alt="Harmony Tower">
									</div>
									<div class="carousel__elemento">
										<img loading="lazy" src="../../assets/img/gallery-23.webp" alt="Empire State Building">
									</div>
									<div class="carousel__elemento">
										<img loading="lazy" src="../../assets/img/gallery-24.webp" alt="Harmony Tower">
									</div>
									<div class="carousel__elemento">
										<img loading="lazy" src="../../assets/img/gallery-25.webp" alt="image">
									</div>
									<div class="carousel__elemento">
										<img loading="lazy" src="../../assets/img/gallery-26.webp" alt="image">
									</div>
									<div class="carousel__elemento">
										<img loading="lazy" src="../../assets/img/gallery-27.webp" alt="Empire State Building">
									</div>
									<div class="carousel__elemento">
										<img loading="lazy" src="../../assets/img/gallery-28.webp" alt="Harmony Tower">
									</div>
									<div class="carousel__elemento">
										<img loading="lazy" src="../../assets/img/gallery-29.webp" alt="Empire State Building">
									</div>
								</div>
								<button aria-label="Siguiente" class="carousel__siguiente">
									<i class="fas fa-chevron-right"></i>
								</button>
							</div>

							<!-- <div role="tablist" class="carousel__indicadores"></div> -->
						</div>
					</div>
				</section>
				<!-- section end-->

				<!-- section start-->
				<section class="section testimonials bg--lgray">
					<div class="testimonials__bg"></div>
					<div class="container">
						<div class="row">
							<div class="col-lg-5" style="height: 600px !important;">
								<div class="heading">
									<span class="heading__pre-title"></span>
									<h3 class="heading__title">
										Casos de <span class="color--orange">éxito</span>
									</h3>
									<span class="heading__layout layout--dgray"></span>
								</div>
								<div class="testimonials__nav"></div>
								<img loading="lazy" class="testimonials__img" src="../../assets/img/testimonials_img.webp" alt="casos"/>
							</div>
							<div class="col-lg-6 offset-lg-1">
								<div class="testimonials-slider">
									<div class="testimonials-slider__item">
										<p>
											IMMEX es un instrumento que permite la importación temporal de materia prima que se utiliza en un proceso o servicio industrial para fabricar, transformar o reparar mercancías extranjeras importadas temporalmente para su posterior exportación, sin cubrir el pago del impuesto general de importación, impuesto al valor agregado y cuando corresponda derechos compensatorios. (Secretaría de Economía 2016). Existen varios beneficios para esto tales como: certificado de Own IMEX y dependiendo del material importado, Solución de Maquila, Sin Impuesto al Valor Agregado (IVA) en materiales de importación (certificado), Tasa de Procesamiento de Importación (IPF) Tarifa plana ($ 341.00 / ajustable anual), por entrada de importación por cliente. En lugar de 0.8% sobre valor personalizado en importaciones definitivas, Productos listos para ser utilizados en la línea de producción 24/7, Ventas y / o transferencia de materia prima con 0% de IVA con resumen virtual de entrada de exportación a CM u OEM´s, One declaración de solicitud de resumen de entrada de exportación virtual única por mes.
										</p>
										<div class="testimonials-slider__author">
											<img loading="lazy" src="../../assets/img/quotes.png" alt="img"/>
											<h6>VMI</h6>
											<span></span>
										</div>
									</div>
									<div class="testimonials-slider__item">
										<p>
											En SBLogistics desarrollamos soluciones con el fin de mejorar la cadena de suministro de nuestros clientes, entendemos los requerimientos de la industria de alta tecnología y, de esta manera, hemos desarrollado las siguientes soluciones logísticas: productos de clientes actuales (cajeros automáticos, placas base, tarjetas gráficas, laptops , Tablets, Audio, Smart Phones. Televisores, Display, Routers, Servidores), Paneles Solares, Servicios Integrados de Cadena de Suministro, Flete Internacional, Servicios de escolta y transporte blindado, servicio de almacenaje y despacho de aduanas, Seguro de carga, depósito fiscal, distribución, Valor Agregado servicios en el almacén (empaquetado, preparación de kits, clasificación, actualizaciones del sistema, reempaquetado).
										</p>
										<div class="testimonials-slider__author">
											<img loading="lazy" src="../../assets/img/quotes.png" alt="img"/>
											<h6>Industria de Alta Tecnología</h6>
											<span></span>
										</div>
									</div>
									<div class="testimonials-slider__item">
										<p>
											En SBLogistics desarrollamos soluciones con el fin de mejorar la cadena de suministro de nuestros clientes, entendemos los requerimientos de la industria automotriz y de esta manera, hemos desarrollo las siguientes soluciones logísticas: Servicios integrados de Supply Chain (International Fright), Servicios de almacenaje y despacho de aduanas, servicios de valor agregado en almacén (repaletización, remodelación, reembalaje), seguros de carga, distribución.
										</p>
										<div class="testimonials-slider__author">
											<img loading="lazy" src="../../assets/img/quotes.png" alt="img"/>
											<h6>Industria Automotriz</h6>
											<span></span>
										</div>
									</div>
									<div class="testimonials-slider__item">
										<p>
											Casos comerciales de CargoWise: Soluciones VMI: Automatización IMMEX para una mayor productividad, toma de decisiones más rápida en su cadena de suministro, reducción de costos, más precisión, menos errores, mejora de las transacciones comerciales, visibilidad en tiempo real, plazos de entrega más cortos, proceso sin papeles.
										</p>
										<div class="testimonials-slider__author">
											<img loading="lazy" src="../../assets/img/quotes.png" alt="img"/>
											<h6>Vinos y Licores</h6>
											<span></span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<!-- section end-->
				<!-- section start-->

				<section class="section bg--lgray front-blog">
					<div class="container">
						<div class="row">
							<div class="col-xl-4 d-flex flex-column align-items-start">
								<div class="heading"><span class="heading__pre-title"></span>
									<h3 class="heading__title">Noticias</h3><span class="heading__layout layout--lgray"></span>
								</div><a class="button button--gray bottom-30" href="newsletter.html"><span>Ver más</span>
									<svg class="icon">
										<use xlink:href="#arrow"></use>
									</svg></a>
								<div class="articles-slider__nav"></div>
							</div>
							<div class="col-xl-8">
								<div class="articles-slider-wrapper">
									<div class="articles-slider">
										<div class="articles-slider__item">
											<div class="article">
												<div class="article__img"><img loading="lazy" class="img--bg" src="../../assets/img/article_3.webp" alt="img"/></div>
												<div class="article__lower newsletter-section">
													<span class="heading__pre-title orange-p">Webinar</span>
													<h6 class="article__title"><a href="https://www.youtube.com/watch?v=zK2-r_4Fq9I" target="_blank">T-Mec Oportunidades - Estrategia Logística y Aduanera</a></h6>
													<p class="article__text">Conoce las soluciones en México para mejorar tu cadena de suministro a través de Aduanas Interiores y el impacto de T-Mec en los aspectos de seguridad.</p>
													<div class="article__details"><span><img loading="lazy" src="../../assets/img/location-pointer.png" alt="pointer"> Evento online</span></div>
													<div class="article__details"><span><img loading="lazy" src="../../assets/img/calendar-interface-symbol-tool.png" alt="calendar"> 17 july, 2020</span><span><img loading="lazy" src="../../assets/img/clock.png" alt="clock"> --</span></div>
												</div>
											</div>
										</div>
										<div class="articles-slider__item">
											<div class="article">
												<div class="article__img"><img loading="lazy" class="img--bg" src="../../assets/img/article_3.webp" alt="img"/></div>
												<div class="article__lower newsletter-section">
													<span class="heading__pre-title orange-p">Webinar</span>
													<h6 class="article__title"><a href="https://www.youtube.com/watch?v=6ds2g7f5E-4&t=47s" target="_blank">Recinto Fiscalizado Estratégico</a></h6>
													<p class="article__text">Conoce los beneficios del modelo más innovador para el comercio internacional de mercancías en México: Recinto Fiscalizado Estratégico. Una Megatendencia de impacto Logístico y Aduanero en el Comercio Internacional de Mercancías.</p>
													<div class="article__details"><span><img loading="lazy" src="../../assets/img/location-pointer.png" alt="pointer"> Evento online</span></div>
													<div class="article__details"><span><img loading="lazy" src="../../assets/img/calendar-interface-symbol-tool.png" alt="calendar"> 12 june, 2020</span><span><img loading="lazy" src="../../assets/img/clock.png" alt="clock"> --</span></div>
												</div>
											</div>
										</div>
										<div class="articles-slider__item">
											<div class="article">
												<div class="article__img"><img loading="lazy" class="img--bg" src="../../assets/img/article_3.webp" alt="img"/></div>
												<div class="article__lower newsletter-section">
													<span class="heading__pre-title orange-p">Webinar</span>
													<h6 class="article__title"><a href="https://www.youtube.com/watch?v=zK2-r_4Fq9I" target="_blank">T-Mec Oportunidades - Estrategia Logística y Aduanera</a></h6>
													<p class="article__text">Conoce las soluciones en México para mejorar tu cadena de suministro a través de Aduanas Interiores y el impacto de T-Mec en los aspectos de seguridad.</p>
													<div class="article__details"><span><img loading="lazy" src="../../assets/img/location-pointer.png" alt="pointer"> Evento online</span></div>
													<div class="article__details"><span><img loading="lazy" src="../../assets/img/calendar-interface-symbol-tool.png" alt="calendar"> 17 july, 2020</span><span><img loading="lazy" src="../../assets/img/clock.png" alt="clock"> --</span></div>
												</div>
											</div>
										</div>
										<div class="articles-slider__item">
											<div class="article">
												<div class="article__img"><img loading="lazy" class="img--bg" src="../../assets/img/article_3.webp" alt="img"/></div>
												<div class="article__lower newsletter-section">
													<span class="heading__pre-title orange-p">Webinar</span>
													<h6 class="article__title"><a href="https://www.youtube.com/watch?v=6ds2g7f5E-4&t=47s" target="_blank">Recinto Fiscalizado Estratégico</a></h6>
													<p class="article__text">Conoce los beneficios del modelo más innovador para el comercio internacional de mercancías en México: Recinto Fiscalizado Estratégico. Una Megatendencia de impacto Logístico y Aduanero en el Comercio Internacional de Mercancías.</p>
													<div class="article__details"><span><img loading="lazy" src="../../assets/img/location-pointer.png" alt="pointer"> Evento online</span></div>
													<div class="article__details"><span><img loading="lazy" src="../../assets/img/calendar-interface-symbol-tool.png" alt="calendar"> 12 june, 2020</span><span><img loading="lazy" src="../../assets/img/clock.png" alt="clock"> --</span></div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<!-- section end-->
				<!-- section start-->
				<section class="section" id="contact"><img loading="lazy" class="img--bg" src="../../assets/img/img-footer-home.webp" alt="bg"/>
					<div class="container">
						<div class="row">
							<div class="col-lg-8 col-xl-6">
								<div class="form-wrapper">
									<!-- contact form start-->
									<form itemprop="potentialAction" class="form contact-form" id="ajax-form" autocomplete="off" >
										<div class="row">
											<div class="col-12">
												<h3 class="contact-form__title">Tenemos lo que estás buscando</h3>
												<p>Nos encantaría ayudarte a llegar a todas partes. Comunícate con nosotros y juntos encontremos el mejor plan para tus necesidades.</p>
											</div>
											<div class="col-md-6">
												<input class="form__field" type="text" name="name" id="name" [(ngModel)]="name" required placeholder="Nombre*"/>
												<div class="validation"></div>
											</div>

											<div class="col-md-6">
												<input class="form__field" type="email" name="email" id="email" [(ngModel)]="email" required placeholder="Correo electrónico*"/>
												<div class="validation"></div>
											</div>
											<div class="col-md-12">
												<select name="servicio" id="servicio" class="form__item" [(ngModel)]="service">
													<option value="">¿Cuál es su segmento de interés?</option>
													<option value="SBLogistics">SBLogistics</option>
													<option value="SBFree Trade Zone">SBFree Trade Zone</option>
													<option value="SBFinancial Services">SBFinancial Services</option>
												</select>
												<div class="validation"></div>
											</div>
											<div class="col-12">
												<textarea class="form__field form__message" name="message2" id="message2" [(ngModel)]="message" placeholder="Mensaje"></textarea>
												<div class="validation"></div>
											</div>
											<div class="col-12">
												<re-captcha
													(resolved)="resolved($event)"
													(error)="onError($event)"
													errorMode="handled"
													siteKey="6LfrQX0aAAAAACt_A36YeSwy9VG1fmdt5V70SQ5C"
												></re-captcha>
												<button class="button button--gray" (click)="sendContactInfo()" ><span>Enviar</span>
													<svg class="icon">
														<use xlink:href="#arrow"></use>
													</svg>
												</button>

											</div>
										</div>
									</form>
									<!-- contact form end-->
								</div>
							</div>
						</div>
					</div>
				</section>
				<!-- section end-->
			</main>
			<!-- footer start-->
			<footer class="page-footer footer_2">
				<div class="container">
					<div class="row">
						<div class="col-md-12 bottom-30">
							<div class="page-footer__logo"><a href="#"><img loading="lazy" src="../../assets/img/logo_sbgroup_webp.webp" alt="logo"/></a></div>
						</div>
						<div class="col-md-6 col-lg-3">
							<div class="page-footer__details">
								<div class="col-12 nopadding">
									<div class="row">
										<div class="col-md-2">
											<svg class="icon">
												<use xlink:href="#pin"></use>
											</svg>
										</div>
										<div class="col-md-10">
											<p><a href="https://goo.gl/maps/gEfHdBFKy9mtziey8" target="_blank">
												Av. Magallanes No. 1155 <br>
												interior 3-B, Colonia Santa Anita, <br>
												Tlaquepaque, Jalisco, C.P. 45600</a></p><br>
										</div>
									</div>
								</div>
								<div class="col-12 nopadding">
									<div class="row">
										<div class="col-md-2">
											<svg class="icon">
												<use xlink:href="#phone"></use>
											</svg>
										</div>
										<div class="col-md-10">
											<p><a href="tel:523336124440"> +52 (33) 3612 4440</a></p>
											<p><a href="tel:18004583460"> + 1 (800) 458 3460</a></p><br>
										</div>
									</div>
								</div>
								<div class="col-12 nopadding">
									<div class="row">
										<div class="col-md-2">
											<svg class="icon">
												<use xlink:href="#mail"></use>
											</svg>
										</div>
										<div class="col-md-10">
											<p><a href="mailto:sales@sbgroup.com.mx"> sales@sbgroup.com.mx</a></p>
											<p><a href="mailto:salesusa@sbgroup.com.mx"> salesusa@sbgroup.com.mx</a></p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-6 col-lg-3 top-30 top-md-0">
							<h6 class="page-footer__title title--white">Sobre  SBGroup</h6>
							<ul class="page-footer__menu list--reset">
								<li><a href="pasos.html">Consultar con experto</a></li>
								<li><a href="contacto.html">Obtener cotización</a></li>
								<li><a href="blog.html">Blog</a></li>
								<li><a href="contacto.html">Contacto</a></li>
								<li><a href="terminosycondiciones.html">Términos y condiciones</a></li>
								<li><a href="avisodeprivacidad.html">Aviso de privacidad</a></li>
							</ul>
						</div>
						<div class="col-md-6 col-lg-3 top-30 top-lg-0">
							<h6 class="page-footer__title title--white">Soluciones Destacadas</h6>
							<ul class="page-footer__menu list--reset">
								<li><a href="sb-logistics.html">SBLogistics</a></li>
								<li><a href="sb-free-trade-zone.html">SBFree Trade Zone</a></li>
								<li><a href="sb-financial-services.html">SBFinancial Services</a></li>
								<li><a href="recursos.html">Recursos</a></li>
							</ul>
						</div>
						<div class="col-md-6 col-lg-3 top-30 top-lg-0">
							<h6 class="page-footer__title title--white">Encuéntranos en</h6>
							<ul class="socials list--reset">
								<li class="socials__item"><a class="socials__link" href="https://www.linkedin.com/company/sbgroup-mexico" target="_blank">
									<svg class="icon">
										<use xlink:href="#linkedin"></use>
									</svg></a></li>
								<li class="socials__item"><a class="socials__link" href="https://www.facebook.com/SBGroupMexico" target="_blank">
									<svg class="icon">
										<use xlink:href="#facebook"></use>
									</svg></a></li>
								<li class="socials__item"><a class="socials__link" href="https://twitter.com/sbgroup_mx" target="_blank">
									<svg class="icon">
										<use xlink:href="#twitter"></use>
									</svg></a></li>
								<li class="socials__item"><a class="socials__link" href="https://www.youtube.com/channel/UCQFOGP1Bfdc037z7C8owPjA" target="_blank">
									<svg class="icon">
										<use xlink:href="#youtube"></use>
									</svg></a></li>
								<li class="socials__item"><a class="socials__link" href="https://www.instagram.com/sbgroup.mx/" target="_blank">
									<svg class="icon">
										<use xlink:href="#inst"></use>
									</svg></a></li>
							</ul>
						</div>
					</div>
					<div class="row top-50 flex-column-reverse flex-sm-row">
						<div class="col-sm-12 top-20 top-sm-0 text-center text-sm-left">
							<div class="page-footer__copyright"></div>
						</div>
					</div>
				</div>
			</footer>
			<!-- footer end-->
		</div>
		<app-svg-icons></app-svg-icons>


