import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoUpdService {

  constructor(
    private titleService: Title,
    private metaService: Meta
  ) { }

  generateTags( config ) {

    console.log( config );
    this.titleService.setTitle( config.title );
    this.metaService.addTags( config.metas );
  }
}
